* {
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased !important; 
    text-rendering:optimizeLegibility !important;
  font-family: 'Nanum';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@supports (-webkit-appearance: none) {
  /* iOS 디바이스에서만 적용되는 스타일 */
  body {
    font-weight: lighter;
  }
}

@font-face {
  font-family: "Nanum"; 
  src: url("./assets/font/NanumSquareNeo-Variable.ttf") format("opentype");  
  font-weight: 100 900;  
  font-style: normal;
}

@font-face {
  font-family: "Poppins"; 
  src: url("./assets/font/Poppins-Bold.ttf") format("opentype"); 
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #262626;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(255,134,103,1) 0%, rgba(73,138,255,1) 50%, rgba(255,134,103,1) 100%);;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}